

























































import { computed, defineComponent, ref } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import WithHeaderLayout from "@/layouts/WithHeaderLayout.vue"
import AgendaTab from "./AgendaTab.vue"
import PastMeetingMinutesTab from "./PastMeetingMinutesTab.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    parent: {
      type: MeetingStructure,
      required: true,
    },
    width: {
      type: Number,
    },
  },
  components: {
    AgendaTab,
    I18nFormattedMessage,
    Icon,
    PastMeetingMinutesTab,
    WithHeaderLayout,
  },
  setup(props, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()

    const isOpen = computed(() => props.value)

    const onToggleClick = () => {
      emit("input", !props.value)
    }

    const currentTabIndex = ref(0)
    const setMove = (e: DragEvent) => {
      e.preventDefault()
      if (e.dataTransfer) {
        if (document.body.clientWidth - e.pageX > 40)
          e.dataTransfer.dropEffect = "move"
        else e.dataTransfer.dropEffect = "none"
      }
    }

    return {
      i18nFormattedMessage: formatMessage,
      isOpen,
      onToggleClick,
      currentTabIndex,
      setMove,
    }
  },
})
