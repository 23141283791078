var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    agenda-part\n    d-flex\n    flex-nowrap\n    justify-space-between\n    align-center\n    px-2\n    py-1\n  ",style:({
    fontWeight: 600,
    height: _vm.heightVal,
    width: '100%',
    background: ("linear-gradient(to right, #d9d9df 0%, #d9d9df " + (_vm.agenda.percent) + "%, #ece9ff " + (_vm.agenda.percent) + "%, #ece9ff 100%)"),
  })},[_c('span',{class:{ ellipsis: _vm.heightVal !== 'auto' },attrs:{"title":_vm.agenda.agenda}},[_vm._v(" "+_vm._s(_vm.agenda.agenda)+" ")]),_c('span',{staticClass:"flex-grow-0 flex-shrink-0 ml-2",class:{ 'grey--text text--darken-1': _vm.createTime(_vm.agenda) == 0 }},[_c('I18nFormattedMessage',{attrs:{"id":"DetailsDrawerAgendaTab.agendaMinutes","default-message":"{minutes}","values":{
        minutes:
          _vm.createTime(_vm.agenda) == 0
            ? ("" + (_vm.agenda.times ? ((_vm.agenda.times) + "分") : ''))
            : ("" + (_vm.agenda.percent === 0 ? '' : '残り') + (_vm.createTime(_vm.agenda)) + "分"),
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }