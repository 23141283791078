


















































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from "@vue/composition-api"
import {
  Meeting,
  Agenda,
  MeetingContent,
  MeetingStructure,
} from "@/models/Meeting"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import AgendaPart from "@/components/AgendaPart.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import DateTime from "@/models/DateTime"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import AgendaInput from "@/views/NewMeetingCreation/AgendaInput.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"

export default defineComponent({
  props: {
    parent: {
      type: MeetingStructure,
      required: true,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
  },
  components: {
    I18nFormattedMessage,
    AgendaPart,
    IconButton,
    DefaultDialog,
    AgendaInput,
  },
  setup(props) {
    let intervalId = 0
    const agendasWithTimer = ref([] as Array<Agenda>)
    const openEdit = ref(false)
    const isFormValid = ref(false)
    const isLoading = ref(false)
    const meetingContent = ref(new MeetingContent())
    const { updateMeetingAsync } = meetingContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    const setAgendas = () => {
      let elapsedTime = new DateTime(new Date()).getDifference(
        props.meeting.startTime,
        "minutes"
      )
      const agendas =
        props.meeting.agendas?.map(a => {
          const time = a.times || 0
          const ret = Object.assign({}, a, {
            percent: Math.max(
              0,
              Math.min(Math.min((elapsedTime / (time || 0.25)) * 100), 100)
            ),
          })
          elapsedTime -= time
          return ret
        }) || []
      return agendas
    }

    agendasWithTimer.value = setAgendas()
    const setValid = (isValid: boolean) => {
      isFormValid.value = isValid
    }
    const editAgenda = () => {
      meetingContent.value = new MeetingContent({
        purpose: props.meeting.purpose,
        goal: props.meeting.goal,
        agendas: props.meeting.agendas?.map(a => new Agenda(a)),
      })
      openEdit.value = true
    }
    const onOk = async () => {
      isLoading.value = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      try {
        await updateMeetingAsync(
          project.siteId,
          context.entityId,
          new Meeting(
            Object.assign({}, props.meeting, {
              purpose: meetingContent.value.purpose,
              goal: meetingContent.value.goal,
              agendas: meetingContent.value.agendas
                ?.filter(a => a.agenda)
                ?.map(a => new Agenda(a)),
            })
          ),
          props.parent.id
        )
        openEdit.value = false
      } finally {
        isLoading.value = false
      }
    }
    onMounted(() => {
      intervalId = window.setInterval(() => {
        agendasWithTimer.value = setAgendas()
      }, 1000)
    })
    onUnmounted(() => {
      clearInterval(intervalId)
    })
    return {
      editAgenda,
      setValid,
      onOk,
      openEdit,
      isFormValid,
      isLoading,
      agendasWithTimer,
      meetingContent,
    }
  },
})
