







































import { computed, defineComponent, PropType } from "@vue/composition-api"
import { Agenda } from "@/models/Meeting"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"

type AgendaType = Agenda & {
  percent: number
}

export default defineComponent({
  props: {
    height: {
      type: Number,
    },
    agenda: {
      type: Object as PropType<AgendaType>,
    },
  },
  components: {
    I18nFormattedMessage,
  },
  setup(props) {
    const heightVal = computed(() =>
      props.height ? `${props.height}px` : "auto"
    )
    const createTime = (agenda: AgendaType) => {
      if (agenda.times) {
        const cuurentMinutes =
          agenda.percent < 0
            ? 0
            : Math.floor(agenda.percent * 0.01 * agenda.times)
        if (agenda.times - cuurentMinutes == 0) return 0
        return agenda.times - cuurentMinutes
      }
      return 0
    }
    return {
      heightVal,
      createTime,
    }
  },
})
